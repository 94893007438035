<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Main',
  components: {
    Toast: () => import('@/components/general/Toast'),
    Spinner: () => import('@/components/general/Spinner'),
    VerticalMenu: () => import('@/components/general/VerticalMenu')
  },

  data () {
    return {
      menuExpanded: true,
      menuOpen: true,
      menuItems: null
    }
  },

  computed: {
    ...mapState([
      'Account',
      'Auth',
      'accessibility',
      'fullscreen',
      'accessibilityFontSize'
    ]),

    shouldShowNavigation () {
      return !this.$route.meta.hideNavbar || false
    },

    permissions () {
      return this.Auth.userData.permissions
    }
  },

  watch: {
    '$route.name' () {
      if (!this.$route.meta.permission) {
        return
      }

      const permission = this.getContextPermission(this.$route.meta.permission.context)

      if (permission === 'write' && [
        'read',
        'write'
      ].indexOf(this.$route.meta.permission.access) !== -1) {
        return
      }

      if (permission === 'read' && permission === this.$route.meta.permission.access) {
        return
      }

      this.$router.push({ name: 'dashboard.index' })
    },

    permissions () {
      this.createMenuItems()
    }
  },

  mounted () {
    this.$theme !== 'default' && document.body.classList.add(`theme-${this.$theme}`)
  },

  created () {
    if (!document.querySelector('.symbol-defs')) {
      const svg = require('@/../public/assets/images/themes/default/svg/symbol-defs.svg')
      const tmp = document.createElement('div')

      tmp.innerHTML = svg
      document.body.appendChild(tmp.childNodes[0])
    }

    this.createMenuItems()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptLogout'
    ]),

    createMenuItems () {
      this.menuItems = [
        {
          items: [
            {
              text: 'global:menu.dashboard',
              icon: 'dashboard',
              link: {
                name: 'dashboard.index'
              },

              enabled: this.getContextPermission('dashboard')
            },
            {
              text: 'global:menu.solutions',
              icon: 'account_balance',
              link: {
                name: 'solutions.index'
              },

              enabled: this.getContextPermission('solution')
            },
            {
              text: 'global:menu.tracks',
              icon: 'timeline',
              link: {
                name: 'tracks.index'
              },

              enabled: this.getContextPermission('tracks')
            },
            {
              text: 'global:menu.programs',
              icon: 'programs',
              link: {
                name: 'programs.index'
              },

              enabled: this.getContextPermission('programs')
            },
            {
              text: 'global:menu.pages',
              icon: 'perm_media',
              link: {
                name: 'thematicPages.index'
              },

              enabled: this.getContextPermission('thematic_pages')
            },
            {
              text: 'Ferramentas',
              icon: 'tools',
              link: {
                name: 'tools.index'
              },

              enabled: this.getContextPermission('tools')
            },
            {
              text: 'global:menu.sessions',
              icon: 'collections_bookmark',
              link: {
                name: 'sessions.index'
              },

              enabled: this.getContextPermission('sessions')
            },
            {
              text: 'global:menu.finances',
              icon: 'finances',
              link: {
                name: 'finance.list'
              },

              enabled: true
            },
            {
              text: 'global:menu.notifications',
              icon: 'email',
              link: {
                name: 'notifications.emails'
              },

              enabled: this.getContextPermission('notification')
            },
            {
              text: 'global:menu.users',
              icon: 'people',
              link: {
                name: 'users.index'
              },

              enabled: this.getContextPermission('users')
            },
            {
              text: 'global:menu.reports',
              icon: 'poll',
              link: {
                name: 'reports.index'
              },

              enabled: this.getContextPermission('reports')
            },
            {
              text: 'global:menu.management',
              icon: 'settings',
              link: {
                name: 'management.index'
                // name: 'management.underconstruction'
              },

              enabled: this.getContextPermission('configurations')
            },
            {
              text: 'global:menu.comments',
              icon: 'comments',
              link: {
                name: 'comments.index'
              },

              enabled: this.getContextPermission('comments')
            },
            {
              text: 'global:menu.tickets',
              icon: 'view-list',
              link: {
                name: 'tickets.underconstruction'
              },

              enabled: true
            },
            {
              text: 'global:menu.logout',
              icon: 'logout',
              bottom: true,
              exact: true,
              callback: () => {
                this.attemptLogout()
              },

              enabled: true
            }
          ]
        }
      ]
    },

    resizeMenu (value) {
      this.menuExpanded = value
    },

    closeMenu () {
      this.menuOpen = false
    }
  }
}
</script>

<template>
  <div id="app">
    <Spinner />
    <VerticalMenu
      v-if="shouldShowNavigation"
      :items="menuItems"
      @resize="resizeMenu"
      @close="closeMenu"
    />
    <main class="main">
      <RouterView :key="$route.fullPath" />
    </main>
    <Toast />
  </div>
</template>

<style src="@/assets/styles/themes/default/global.css"></style>
